<template>
<!--  <vs-button @click="generatePDF()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์ใบเสร็จชั่วคราว</vs-button>-->
</template>

<script>
  import jsPDF from "jspdf";
  import { logo_img } from "./assets/logoGurada";
  import 'jspdf-autotable'
  const fs = require('fs');
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  export default {
    components: {
      jsPDF,
    },
    name: "รายงานเข้าจังหวัดสตูล",
    data() {
      return {
      }
    },
    methods: {
      generatePDF() {
        let fileName = 'รายงานค่าน้ำสูงผิดปกติ';
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.addImage(logo_img, 'PNG', 98, 15, 22, 25, {align: 'center'});
        doc.setFont('THSarabunNew', 'normal');
        doc.setFontSize(14);
        doc.text('ด่านขาเข้า' , 20, 30);
        doc.text('บันทึก' , doc.internal.pageSize.getWidth() / 2, 46);
        doc.text('ที่......................................................', 140, 56);
        doc.text('', doc.internal.pageSize.getWidth() / 2, 52, {align: 'center'});
        doc.text('เรื่อง   ขออนุญาตเดินทางเข้าจังหวัดสตูล', 20, 65);
        doc.text('วันที่..................................................๒๕๖๓', (doc.internal.pageSize.getWidth() / 2) +10, 76);
        doc.text('ข้าพเจ้า...............................................................................................................อายุ.................................................ปี', 40, 86);
        doc.text('เลขบัตรประจำตัวประชาชน..............................................................................................................................................................................', 20, 92);
        doc.text('ที่อยู่.........................................................................................................................................โทรศัพท์............................................................', 20, 98);
        doc.text('ขอให้ถ้อยคำต่อ.............................................................................................ตำแหน่ง.......................................................................................', 20, 104);
        doc.text('ด้วยความจริง ดังนี้', 20, 110);
        doc.text('ข้าพเจ้าเดินทางมาจาก.......................................................................................................มีความประสงค์จะเดินทาง', 40, 118);
        doc.text('เข้าสู่จังหวัดสตูล โดยมีวัตถุประสงค์ เพื่อ...........................................................................................................................................................', 20, 124);
        doc.text('...........................................................................................................................................................................................................................', 20, 130);
        doc.text('โดยจะพักอาศัยที่................................................................................................................................................................................................', 20, 136);
        doc.text('และจะเดินทางออกจากจังหวัดสตูล วันที่..............เดือน...........................................พ.ศ...............................เวลา.........................................น.', 20, 142);
        doc.text('ข้าพเจ้ายืนยันว่าได้อ่านหนังสือฉบับนี้โดยตลอดแล้ว ขอยืนยันว่าเป็นความจริงทุกประการ', 40, 148);
        doc.text('จึงได้ลงลายมือไว้เป็นหลักฐาน', 20, 154);
        // doc.text('ที่อยู่   ' + users[i].member_address_no + ' ' + users[i].member_soi + ' ' + users[i].member_road + ' ' + users[i].member_address, 10, 81);
        doc.setFont('THSarabunNew', 'normal');
        doc.setProperties({
          title: `report${new Date().toLocaleString()}`
        });
        doc.output("dataurlnewwindow");
      }
    },
    created() {
      this.generatePDF();
    }
  }
</script>
